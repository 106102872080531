<template>
  <div class="w-full">
    <vs-row vs-w="12" vs-align="flex-start" vs-justify="center">
      <vs-col vs-w="12" vs-align="center" vs-justify="center">
        <Agenda
          :blockActions="blockActions"
          @data-selecionada="setDataSelecionada"
        ></Agenda>
      </vs-col>
      <vs-col vs-w="12" vs-align="center" vs-justify="center" class="mt-5">
        <div class="w-full">
          <div
            id="agenda-lev"
            class="customize-table vs-con-loading__container"
          >
            <div v-if="servicos.length == 0">
              <div class="pt-20 scroll-agenda-lev con-colors">
                <ul class="pt-20">
                  <li class="danger-box">
                    <h2 class="p-5" style="color: white !important">
                      Nenhum Levantamento Agendado
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="w-full">
              <div class="w-full" style="background: black">
                <h5 class="text-white p-2">
                  Total de Serviços: {{ servicos.length }}
                </h5>
              </div>
              <!-- <VuePerfectScrollbar
                class="scroll-agenda-lev"
                :settings="settings"
              >-->
              <vs-table
                search
                stripe
                maxHeight="62vh"
                pagination
                class="m-0 mx-2"
                :data="servicos"
                max-items="10"
              >
                <template slot="thead">
                  <vs-th sort-key="id_contrato" style="width: 5%" id="center"
                    >Contrato</vs-th
                  >
                  <vs-th sort-key="id" style="width: 10%" id="center"
                    >N° Lev</vs-th
                  >
                  <vs-th sort-key="id_cliente" style="width: 20%"
                    >Cliente</vs-th
                  >
                  <vs-th style="width: 15%" id="center">Horário Marcado</vs-th>
                  <vs-th style="width: 5%" id="center">Ações</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="index"
                    v-for="(row, index) in data"
                    :style="
                      row.executado == 1
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : row.executado == 2
                        ? 'color:#dd3522;background-color: #f5efef !important'
                        : ''
                    "
                  >
                    <vs-td :data="row.id_contrato" class="text-center">
                      <a
                        target="_blank"
                        :href="'contrato?id=' + row.id_contrato"
                        style="color: darkblue; cursor: pointer"
                      >
                        <b>{{ row.id_contrato }}</b>
                      </a>
                    </vs-td>
                    <vs-td :data="row.id" class="text-center">
                      <b>{{ row.id }}</b>
                    </vs-td>

                    <vs-td :data="row.id_cliente">
                      <VisualizarCliente
                        @update="close()"
                        :id_cliente="row.id_cliente"
                        :nomeCliente="row.nome_cliente"
                      />
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.horario }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <div class="w-full">
                        <vs-row
                          vs-w="12"
                          vs-align="flex-start"
                          vs-justify="center"
                        >
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                          >
                            <LevInfo :lev="row" />
                          </vs-col>
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                            v-if="row.executado != 2"
                          >
                            <vs-button
                              class="px-0 py-0 m-0"
                              icon="remove_red_eye"
                              @click="redirectBlank(row)"
                              :disabled="!(row.executado == 1)"
                              color="success"
                              size="small"
                              type="filled"
                            ></vs-button>
                            <!-- </a> -->
                          </vs-col>
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                            v-else
                          >
                            <LevNaoExec :lev="row" />
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <!-- </VuePerfectScrollbar> -->
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import Agenda from "./components/agenda.vue"
import LevNaoExec from "./components/levNaoExec"
import LevInfo from "./components/levInfo"
import VisualizarCliente from "./../../cliente/visualizar.vue"
export default {
  data () {
    return {
      filtro: {
        data: this.$formartData.dataFiltro(),
        dataHoje: this.$formartData.dataFiltro()
      },
      servicos: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      blockActions: false,
      relatorioUrl: ""
    }
  },
  methods: {
    async setDataSelecionada (data) {
      this.filtro.data = await this.$formartData.formatar(data, 1)
      await this.getServicosByData()
    },
    async redirectBlank (os) {
      const link = `${this.relatorioUrl}/?os=${os.id}&lev=1`
      var a = document.createElement("a")
      a.target = "_blank"
      a.href = link
      a.click()
    },
    async getServicosByData () {
      await this.$vs.loading({ container: "#agenda-lev", scale: 0.6 })
      this.blockActions = true
      try {
        const res = await this.$http.post("getAgendaLev", this.filtro)
        this.servicos = res.servicos
        this.relatorioUrl = res.relatorioUrl
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockActions = false
        await this.$vs.loading.close("#agenda-lev > .con-vs-loading")
      }
    }
  },
  async mounted () {
    await this.getServicosByData()
  },
  components: {
    ...components,
    Agenda,
    VisualizarCliente,
    LevNaoExec,
    LevInfo
  }
};
</script>
<style lang="scss">
.scroll-agenda-lev {
  height: 73vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.customize-table {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
