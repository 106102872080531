<template>
  <div>
    <vs-button
      icon="info"
      color="warning"
      @click="openModal"
      size="small"
      :disabled="lev.executado != 1"
      type="filled"
      style="color: white"
    ></vs-button>

    <vs-popup :title="'LEVANTAMENTO N° ' + lev.id" :active.sync="levModalInfo">
      <vs-row vs-type="flex" vs-align="center" id="remarcar" class="vs-con-loading__container">
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="my-5">
          <div class="w-full p-0">
            <h4>
              Horas Iniciais:
              <span
                style="font-size: 24px; color:green;"
              >{{ levInfo.horas_iniciais }}</span>
            </h4>
            <h4>
              Tecnicos Iniciais:
              <span
                style="font-size: 24px; color:green;"
              >{{ levInfo.tecnicos_iniciais }}</span>
            </h4>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="my-5">
          <div class="w-full p-0">
            <h4>
              Horas manutencao:
              <span
                style="font-size: 24px; color:green;"
              >{{ levInfo.horas_manutencao }}</span>
            </h4>
            <h4>
              Tecnicos manutencao:
              <span
                style="font-size: 24px; color:green;"
              >{{ levInfo.tecnicos_manutencao }}</span>
            </h4>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="my-5">
          <div class="w-full p-0">
            <h4>
              FOG:
              <span style="font-size: 24px; color:green;">{{ status[levInfo.fog] }}</span>
            </h4>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="my-5">
          <div class="w-full p-0">
            <h4>
              ESCADA:
              <span style="font-size: 24px; color:green;">{{ status[levInfo.escada] }}</span>
            </h4>
          </div>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="my-5">
          <div class="w-full p-0">
            <h6>
              Obs:
              <span style="font-size: 18px; color:black;">{{ levInfo.obs }}</span>
            </h6>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    lev: {
      required: true
    }
  },
  data() {
    return {
      levModalInfo: false,
      levInfo: {},
      status: [`NAO`, "SIM"]
    };
  },
  methods: {
    async openModal() {
      this.$emit("close");
      this.levModalInfo = true;
      await this.getLevExecInfo();
    },
    async getLevExecInfo() {
      try {
        this.levInfo = await this.$http.post("/getLevExecInfo", {
          id: this.lev.id
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async close() {
      this.levModalInfo = false;
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
